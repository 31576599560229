import React from 'react'
import '../App.css'
import './HeroSection.css'

function HeroSection() {
    return (
        <div className='hero-container'>
            <h1>Kenneth Li</h1>
            {/* <p>filler</p> */}
        </div>
    )
}

export default HeroSection
